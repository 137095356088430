<template>
  <div>
    <app-breadcrumb />
    <div class="page-container out-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="80px" size="mini">
          <el-form-item label="报单时间">
            <!-- 时间起始 -->
            <el-date-picker
                v-model="listQuery.from"
                type="date"
                :picker-options="this.startDate"
                style="width: 130px;"
                value-format="yyyy-MM-dd"
                placeholder="开始时间"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 时间结束 -->
            <el-date-picker
                    v-model="listQuery.to"
                    :picker-options="this.end_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="结束时间"
            />
          </el-form-item>
          <el-form-item label="顾客编号:">
            <el-input v-model.trim="listQuery.memberNo" style="width:160px;" />
          </el-form-item>
          <el-form-item label="顾客姓名:">
            <el-input v-model.trim="listQuery.memberName" style="width:160px;" />
          </el-form-item>
          <el-form-item label="出库时间">
            <!-- 时间起始 -->
            <el-date-picker
                    v-model="listQuery.fromOut"
                    type="date"
                    :picker-options="this.startDate"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="开始时间"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 时间结束 -->
            <el-date-picker
                    v-model="listQuery.toOut"
                    :picker-options="this.end_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="结束时间"
            />
          </el-form-item>
          <el-form-item label="单据编号:">
            <el-input v-model.trim="listQuery.orderNo" style="width:160px;" />
          </el-form-item>
<!--          <el-form-item label="订单状态:">
            <el-select v-model="listQuery.status" clearable style="width:160px;">
              <el-option v-for="item in sendStatusList" :key="item.code" :label="$lt(initDict.sendStatus, item.code)" :value="item.code" />
            </el-select>
          </el-form-item>-->
          <el-form-item style="margin-left:5px;">
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增出货单</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="outTable" :data="list" v-loading="loading" style="width: 100%" size="mini" border fit stripe class="t-out">
        <el-table-column type="selection" :selectable="checkSelectable" width="40" align="center" />
<!--        <el-table-column label="操作" width="40" align="center">-->
<!--          <template slot-scope="{row}">-->
<!--            <div class="opts">-->
<!--              <el-tooltip class="item" effect="dark" content="详情" placement="top">-->
<!--                <i class="button el-icon-search" @click="handleView(row)"/>-->
<!--              </el-tooltip>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="订单编号" prop="orderNo" width="140" align="center" />
        <el-table-column label="订单类型" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="顾客编号" prop="memberNo" align="center" />
        <el-table-column label="姓名" prop="memberName" align="center" />
        <el-table-column label="金额" prop="totalAmount" width="80" align="center" />
        <el-table-column label="消费指数" prop="totalFv" width="80" align="center" />
        <el-table-column label="换货积分" prop="totalpointa0" width="80" align="center" />
        <el-table-column label="状态" width="60" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.sendStatus, row.sendFlag) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报单时间" prop="createdTime" width="130" align="center" />
        <el-table-column label="顾客姓名" prop="memberName"  align="center" />
        <el-table-column label="出库单号" prop="outNo" align="center" />
        <el-table-column label="急提时间" prop="urgentTime"  align="center" />
<!--        <el-table-column type="expand">-->
<!--          <template slot-scope="{row}">-->
<!--            <el-form label-position="right" label-width="80px" inline class="out-table-expand">-->
<!--              <el-form-item label="顾客姓名:">-->
<!--                <span>{{ row.memberName }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="出库单号:">-->
<!--                <span>{{ row.outNo }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="急提时间:">-->
<!--                <span>{{ row.urgentTime }}</span>-->
<!--              </el-form-item>-->
<!--            </el-form>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { fetchOutOrders } from '@/api/order/order-out'
export default {
  name: 'AgentOrderOut',
  components: { Pagination },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      startDate:{
        disabledDate: time => {
          const start = new Date()
          return time.getTime() < start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        }
      },
      listQuery: {
        page: 1,
        limit: 10,
        memberNo: undefined,
        memberName: undefined,
        orderNo: undefined,
        status: undefined,
        from: undefined,
        to: undefined,
        fromOut: undefined,
        toOut: undefined
      },
      createDates: undefined,
      outDates: undefined,
      initDict: {
        sendStatus: 'status.po.stockout',
        orderType: 'po.allordertype.cn'
      }
    }
  },
  computed: {
    sendStatusList() {
      return this.$ll(this.initDict.sendStatus)
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      fetchOutOrders(this.listQuery).then(res => {
        this.list = res.data.records
        this.total = res.data.total ? +res.data.total : 0
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    checkSelectable(row) {
      return row.sendFlag === 0
    },
    handleView(row) {
      console.log(row)
    },
    handleAdd() {
      const selection = this.$refs['outTable'].selection
      const orders = selection.map(ele => ele.id)
      if (!orders || !orders.length) {
        this.$message.error('至少需要选择一个订单')
      } else {
        this.$router.push(`/order/out/detail?id=${orders}`)
      }
    }
  }
}
</script>

<style lang="scss">
.out-container {
  .t-out {
    .el-table__expanded-cell {
      padding: 0 10px;
      .out-table-expand {
        .el-form-item {
          margin-bottom: 0;
          .el-form-item__content {
            min-width: 150px;
          }
        }
      }
    }
  }
}
</style>
